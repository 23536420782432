import React, { useState } from "react"
import { Link, graphql } from 'gatsby'

//components
import Layout from "../components/layout";
import Seo from "../components/seo";
import EmailResume from "../components/Home/email-resume";
import ApplyForJobForm from "../components/applyForJobForm";

const professional_benifits = [
  { point: "We treat every member of our team with the highest regard." },
  { point: "We provide a fun, inviting place to work where you will find friendly competition to become an even better professional throughout your future career." },
  { point: "You will work with the latest technology and keep your skills updated at all times." },
  { point: "Work life balance and never leaves you feeling overwhelmed – we value your mental health" },
  { point: "Global Clients - Gain experience with an international client base" }
]

const personal_benifits = [
  { point: "Flexible work schedule year-round." },
  { point: "5 Days Working" },
  { point: "Personalized mentorship and networking" },
  { point: "Fully paid PTO and leave options" },
  { point: "Continued education with free conferences, courses, and  workshops at DecodeUp." },
  { point: "Global education" },
  { point: "Learn from our partners around the world in over 70  countries." },
  { point: "Access to online courses, the latest books and more at no expense." }
]

const JobOpeningSinglePage = (props) => {
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const [showAll, setShowAll] = useState(false)
  const limit = 105;
  const showMore = (event) => {
    setShowAll(true)
  }
  const showLess = (event) => {
    setShowAll(false)
  }
  const contntLength = (content) => {
    const lenCnt = content.substr(3, content.length - 4)
    const final_l = lenCnt.length
    return final_l
  }
  const { job: post } = props.data
  const { edges: morejobs } = props.data.moreJobs


  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "single-job-opening"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""} />

        <section className="mt-60 mb-20 md:mt-67 lg:mt-20 lg:mb-1-19">
          <div className="container mx-auto">
            <div className="pt-8 md:pt-70 mx-auto job-opning-contain">
              <div className="mb-5-2 flex flex-row justify-between md:items-end">
                <p className="font-poppings-bold text-h5 lg:text-h4 text-cyan">Apply Now</p>
                <div className="flex flex-wrap items-center lg:items-starts">
                  <div className="mr-6-1 lg:mr-42">
                    <h4 className="font-poppings-bold text-rp3 lg:text-h4 text-cyan">{String(post.frontmatter.position).padStart(2, '0')}</h4>
                    <p className="text-rh6 lg:text-p4 font-poppings-normal text-dark-grey">POSITIONS</p>
                  </div>
                  <div>
                    <h4 className="font-poppings-bold text-rp3 lg:text-h4 text-cyan">{post.frontmatter.experience}</h4>
                    <p className="text-rh6 lg:text-p4 font-poppings-normal text-dark-grey">EXPERIENCE</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-row flex-wrap lg:flex-nowrap justify-between border border-tone-ternary">
                <div className="p-5 md:pt-39 md:px-8 lg:pl-20 lg:pr-65 w-full md:w-1/2 2md:w-3/5 lg:w-full du-job-details-sec">
                  <div className="mb-5 md:mb-34">
                    <h3 className="mb-1 lg:mb-2-1 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">{post.frontmatter.title}</h3>
                    {contntLength(post.html) <= limit ?
                      <div
                        className="job-related-content text-p4 lg:text-p2 font-worksans-normal text-term-primary"
                        dangerouslySetInnerHTML={{ __html: post.html }}
                      />
                      :
                      (showAll ?
                        <div
                          aria-hidden="true"
                          onClick={(event) => showLess(event)}
                          className="job-related-content text-p4 lg:text-p2 font-worksans-normal text-term-primary"
                          dangerouslySetInnerHTML={{ __html: `${(post.html.substring(0, contntLength(post.html)))} <a class="cursor-pointer font-poppings-bold text-cyan text-rh6 lg:text-p4 moreless-link">Read Less</a>` }}
                        />
                        :
                        <div
                          aria-hidden="true"
                          onClick={(event) => showMore(event)}
                          className="job-related-content text-p4 lg:text-p2 font-worksans-normal text-term-primary"
                          dangerouslySetInnerHTML={{ __html: `${(post.html.substring(0, limit))}... <a class="cursor-pointer font-poppings-bold text-cyan text-rh6 lg:text-p4 moreless-link">Read More</a>` }}
                        />
                      )}
                  </div>
                  <div className="mb-5 md:mb-34">
                    <h6 className="pt-2-3 pr-1-2 inline-block font-poppings-bold text-h6 text-cyan border-t border-cyan">RESPONSIBILTIES</h6>
                    <ul className="pt-3 text-p4 lg:text-p2 font-worksans-normal text-term-primary">
                      {post.frontmatter.responsibilities &&
                        post.frontmatter.responsibilities.map(({ points }, i) => (
                          <li key={i} className="pl-4-1 job-opening-inner-list relative list-inside">{points}</li>
                        ))}
                    </ul>
                  </div>
                  <div className="mb-5 md:mb-34">
                    <h6 className="pt-2-3 pr-1-2 inline-block font-poppings-bold text-h6 text-cyan border-t border-cyan">PROFESSIONAL BENEFITS</h6>
                    <ul className="pt-3 text-p4 lg:text-p2 font-worksans-normal text-term-primary">
                      {professional_benifits &&
                        professional_benifits.map(({ point }, j) => (
                          <li key={j} className="pl-4-1 job-opening-inner-list relative list-inside">{point}</li>
                        ))}
                    </ul>
                  </div>
                  <div className="mb-5 md:mb-34">
                    <h6 className="pt-2-3 pr-1-2 inline-block font-poppings-bold text-h6 text-cyan border-t border-cyan">PERSONAL BENEFITS</h6>
                    <ul className="pt-3 text-p4 lg:text-p2 font-worksans-normal text-term-primary">
                      {personal_benifits &&
                        personal_benifits.map(({ point }, p) => (
                          <li key={p} className="pl-4-1 job-opening-inner-list relative list-inside">{point}</li>
                        ))}
                    </ul>
                  </div>

                </div>

                {/* job-opening form section */}
                <ApplyForJobForm jobFor={post.frontmatter.title} />
              </div>
            </div>

            {morejobs.length !== 0 &&
              <div className="pt-10 md:pt-1-19 job-opning-contain mx-auto">
                <div className="mb-5">
                  <p className="font-poppings-bold text-h5 lg:text-h4 text-cyan">
                    More Job Openings
                  </p>
                </div>
                {morejobs.map(({ node: morejob }, m) => (
                  <div key={m} >
                    <div className="mb-6-1 lg:mb-5 pt-6-6 pb-6-6 pl-5 pr-1 sm:pr-5 lg:pt-39 lg:pb-41 lg:px-20 border border-tone-ternary job-opning-box">
                      <h3 className="mb-1 lg:mb-2-1 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">{morejob.frontmatter.title}</h3>
                      <p className="pr-6-1 lg:pr-0 xl:pr-10 mb-5-2 lg:mb-6-4 text-p4 lg:text-p2 font-worksans-normal text-term-primary">{morejob.frontmatter.excerpt}</p>
                      <div className="flex flex-wrap justify-between items-starts">
                        <Link
                          to={morejob.fields.slug}
                          className="py-2-3  md:py-3 px-38 font-poppings-bold inline-block bg-celeste text-rh5 lg:text-h5 text-dark-blue text-center shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
                        > Apply Now </Link>
                        <div className="flex flex-wrap items-center lg:items-starts">
                          <div className="mr-6-1 lg:mr-42">
                            <h4 className="font-poppings-bold text-rp3 lg:text-h4 text-cyan">{String(morejob.frontmatter.position).padStart(2, '0')}</h4>
                            <p className="text-rh6 lg:text-p4 font-poppings-normal text-dark-grey">POSITIONS</p>
                          </div>
                          <div>
                            <h4 className="font-poppings-bold text-rp3 lg:text-h4 text-cyan">{morejob.frontmatter.experience}</h4>
                            <p className="text-rh6 lg:text-p4 font-poppings-normal text-dark-grey">EXPERIENCE</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="mt-6-6 md:mt-10 lg:mt-60 text-center">
                  <Link
                    to={'/job-opening'}
                    className="mb-2 md:mb-0 py-2-3 px-6-6  md:py-3 md:px-38 font-poppings-bold inline-block bg-celeste text-rh5 lg:text-h5 text-dark-blue text-center shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
                  > View All </Link>
                </div>
              </div>
            }
          </div>
        </section>

        {/* send resume section */}
        <EmailResume />

      </Layout>
    </>
  )
}
export default JobOpeningSinglePage;

export const JobOpeningPageQuery = graphql`
query SingleJobOpening($id: String!,$job_cat: String!) {
  job: markdownRemark(id: { eq: $id }) {
    id
    html
    frontmatter {
      job_category
      title
      date
      experience
      position
      responsibilities {
        points
      }
    }
  }
  moreJobs: allMarkdownRemark(
    limit: 2
    filter: { 
      frontmatter: { job_category: {eq: $job_cat } }, 
      id: { ne: $id }
    }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            job_category
            title
            date
            excerpt
            experience
            position
          }
          id
        }
      }
    }
    awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`