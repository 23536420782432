import React, { useState } from 'react'
import Axios from "axios"

// images
import Inputtwitter from "../images/Input-twitter.svg";
import Inputgithub from "../images/Input-github.svg";
import Inputdribbble from "../images/Input-dribbble.svg";
import Inputbehance from "../images/Input-behance.svg";
import uploadCv from "../images/upload-cv.svg";
import whatsapp from "../images/uploads/whatsapp.svg";
import email from "../images/uploads/email.svg";
import telephone from "../images/uploads/telephone.svg";


var phoneRgx = /^\+?(?:[\d]*)$/
var twitterRgx = /^http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)$/
var githubRgx = /^http(?:s)?:\/\/(?:www\.)?github\.com\/([a-zA-Z0-9_]+)$/
var dribbleRgx = /^http(?:s)?:\/\/(?:www\.)?dribbble\.com\/([a-zA-Z0-9_]+)$/
var behanceRgx = /^http(?:s)?:\/\/(?:www\.)?behance\.net\/([a-zA-Z0-9_]+)$/
var urlRex = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
var emailRgx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/


const ApplyForJobForm = (props) => {

  const [fields, setFields] = useState({})
  const [errors, setErrors] = useState({})
  const [succMsg, setSuccMsg] = useState(false)
  const [errHandler, setErrHandler] = useState('')
  const [loading, setLoading] = useState(false);
  const [resumeText, setResumeText] = useState('Select your resume/CV')
  // const mainRef = useRef(null);
  // ref={mai++nRef}

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!fields.firstname) {
      formIsValid = false;
      errors.firstname = "Field is require.";
    }
    if (!fields.lastname) {
      formIsValid = false;
      errors.lastname = "Field is require.";
    }
    if (!fields.email) {
      formIsValid = false;
      errors.email = "Field is require.";
    }
    if (!emailRgx.test(fields.email)) {
      formIsValid = false;
      errors.email = "Field is valid.";
    }

    if (fields.twitter && !urlRex.test(fields.twitter)) {
      formIsValid = false;
      errors.twitter = "Field is not valid.";
    }
    if (fields.github && !urlRex.test(fields.github)) {
      formIsValid = false;
      errors.github = "Field is not valid.";
    }
    if (fields.dribble && !urlRex.test(fields.dribble)) {
      formIsValid = false;
      errors.dribble = "Field is not valid.";
    }
    if (fields.behance && !urlRex.test(fields.behance)) {
      formIsValid = false;
      errors.behance = "Field is not valid.";
    }
    if (!fields.phone) {
      formIsValid = false;
      errors.phone = "Field is require.";
    }
    if (!fields.somethingAboutYourself) {
      formIsValid = false;
      errors.somethingAboutYourself = "Field is require.";
    }
    if (!fields.whereYouHearAboutThisJob) {
      formIsValid = false;
      errors.whereYouHearAboutThisJob = "Field is require.";
    }

    if (fields.resumeCV && (fields.resumeCV.name.match(/(png|PNG|jpg|JPG|pdf|PDF|doc|DOC|DOCX|docx|ODT|odt|ODS|ods)$/)) === null) {
      formIsValid = false;
      errors.resumeCV = "Field is not valid.";
    }


    setErrors(errors)
    return formIsValid;
  }


  const handleChange = (event) => {
    event.persist();
    setFields(fields => ({ ...fields, [event.target.name]: event.target.value }));
    setErrors(errors => ({ ...errors, [event.target.name]: '' }))
  }
  const handleRgxValidation = (e) => {

    var { name, value } = e.target

    switch (name) {
      case "phone":

        if (value === '' || phoneRgx.test(value)) {
          if (value.length <= 13) {
            setFields(fields => ({ ...fields, 'phone': value }))
            // fields.phone = value
            errors.phone = "";
          } else {
            errors.phone = "Please enter number only.";
          }
        } else {
          errors.phone = "Please enter number only.";
        }
        break;
      case "email":
        setFields(fields => ({ ...fields, 'email': value }))
        if (emailRgx.test(value)) {
          errors.email = "";
        } else {
          errors.email = "Please enter valid email";
        }
        break;
      case "twitter":

        setFields(fields => ({ ...fields, 'twitter': value }))
        if (value) {
          if (urlRex.test(value)) {
            errors.twitter = "";
          } else {
            errors.twitter = "Please enter valid email";
          }
        } else {
          errors.twitter = "";
        }
        break;
      case "github":

        setFields(fields => ({ ...fields, 'github': value }))
        if (value) {
          if (urlRex.test(value)) {
            errors.github = "";
          } else {
            errors.github = "Please enter valid email";
          }
        } else {
          errors.twitter = "";
        }
        break;
      case "dribble":

        setFields(fields => ({ ...fields, 'dribble': value }))
        if (value) {
          if (urlRex.test(value)) {
            errors.dribble = "";
          } else {
            errors.dribble = "Please enter valid email";
          }
        } else {
          errors.twitter = "";
        }
        break;
      case "behance":

        setFields(fields => ({ ...fields, 'behance': value }))
        if (value) {
          if (urlRex.test(value)) {
            errors.behance = "";
          } else {
            errors.behance = "Please enter valid email";
          }
        } else {
          errors.twitter = "";
        }
        break;
      default:
        break;
    }

    setErrors(errors)
    // setFields(fields)
  }
 const handleFileChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let reader = new FileReader();
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      reader.readAsDataURL(uploadedFile);

      switch (name) {
        case "resumeCV":
          if (!uploadedFile.name.match(/\.(png|PNG|jpg|JPG|pdf|PDF|doc|DOC|DOCX|docx|ODT|odt|ODS|ods)$/)) {
            errors.resumeCV = "Please select a valid file.";
            reader.onloadend = () => {
              setFields((fields) => ({ ...fields, resumeCV: uploadedFile }));
            };
          } else {
            errors.resumeCV = "";
            reader.onloadend = () => {
              setFields((fields) => ({ ...fields, resumeCV: uploadedFile }));
            };
          }
          setResumeText(uploadedFile.name);
          break;
        default:
          break;
      }
    } else {
      setFields((fields) => ({ ...fields, resumeCV: null }));
      setResumeText("Select your resume/CV");
    }

    setErrors(errors);
  };


  const contactSubmit = (e) => {
    e.preventDefault();
    // mainRef.current.scrollIntoView({ 
    //     behavior: "smooth", 
    //     block: "nearest"
    //  })

    if (handleValidation()) {
      // alert("stop")
      // return
      setLoading(true);


      const formData = new FormData();
      formData.set("firstname", fields.firstname);
      formData.set("lastname", fields.lastname);
      formData.set("email", fields.email);
      formData.set("jobFor", props.jobFor);
      formData.set("phone", fields.phone);
      formData.set("somethingAboutYourself", fields.somethingAboutYourself);
      formData.set("twitter", fields.twitter || '');
      formData.set("github", fields.github || '');
      formData.set("dribble", fields.dribble || '');
      formData.set("behance", fields.behance || '');
      formData.set("whereYouHearAboutThisJob", fields.whereYouHearAboutThisJob);
      formData.append("resumeCV", fields.resumeCV);

      Axios.post(`https://api.decodeup.com/api/job/users/create`, formData)
        .then(result => {

          if (result.data.status) {
            setFields({})
            setResumeText('Select your resume/CV')
            setSuccMsg(true)
            setErrHandler('')
            setLoading(false);
            setTimeout(() => {
              setSuccMsg(false)
            }, 5000);
          } else {
            setErrHandler(result.data.message);
            setLoading(false);
          }
        })
        .catch(err => {        
          setLoading(false);
        })

    }
    //  else {
    //     alert("Form has errors.");
    // }
  }



  return (
    <>
      <div className="w-full md:w-1/2 2md:w-2/5 lg:w-full du-apply-job-sec">
        <div className="px-1-2 pt-1-2 bg-background-secondary ">
          <div className="bg-cyan du-apply-job-contact">
            {/* <h4 className="font-poppings-normal text-rp1 lg:text-h4 text-term-primary">Connect with us!</h4> */}
            <div className="p-4 lg:pb-2 lg:px-5 lg:py-6">
              <h4 className="font-poppings-bold text-rp1 lg:text-h4 text-white">
                Connect with us!
              </h4>
              <div className="flex flex-wrap items-center justify-between">
                <div className="w-full flex flex-col mms:flex-row md:flex-col du-connect-left-side py-2-3">
                  <div className="mms:px-1 md:px-0 mms:w-4/12 md:w-full flex flex-row mms:flex-col md:flex-row  items-center">
                    <img
                      className="mr-4 mms:mx-auto md:mr-4 md:ml-0"
                      src={telephone}
                      alt="decodeup"
                    />
                    <div className="grow mms:text-center md:text-left">
                      <p className="mb-0 text-p4 font-poppings-normal text-white opacity-60">
                        GIVE US A CALL NOW
                      </p>
                      <a
                        href={`tel:+918758332633`}
                        className=" nav-family font-poppings-bold text-p2 text-white"
                      >
                        {"+91 8758-332633"}
                      </a>
                    </div>
                  </div>
                  <div className="mms:px-1 md:px-0 mms:w-4/12 md:w-full flex flex-row mms:flex-col md:flex-row  items-center mt-3 mms:mt-0 md:mt-3">
                    <img
                      className=" mr-4 mms:mx-auto md:mr-4 md:ml-0"
                      src={email}
                      alt="decodeup"
                    />
                    <div className="grow mms:text-center md:text-left">
                      <p className="mb-0 text-p4 text-white font-poppings-normal opacity-60">
                        WRITE US AN EMAIL
                      </p>
                      <a
                        href={`mailto:hr@decodeup.com `}
                        className="nav-family font-poppings-bold text-p2 text-white"
                      >
                        {"hr@decodeup.com "}
                      </a>
                    </div>
                  </div>

                  <div className="mms:pl-1 mms:pr-2  md:px-0 mms:w-4/12  md:w-full block lg:hidden mt-3 mms:mt-0 md:mt-3 lg:pl-6 lg:py-2-3 flex flex-row mms:flex-col md:flex-row items-center mms:justify-between md:justify-start">
                    <img
                      className="mr-4 mms:mx-auto md:mr-4 md:ml-0"
                      src={whatsapp}
                      alt="decodeup"
                    />
                    <a
                      target="_blank"
                      href="https://wa.me/message/UXSURN63L42OA1"
                      className="mms:px-2 md:px-0 block text-center md:text-left underline font-poppings-bold text-white text-h6"
                    >
                      Chat with our Recruitment Team
                    </a>
                  </div>
                </div>
                <div className="hidden lg:block lg:pl-6 lg:py-2-3 flex flex-row mms:flex-col items-center du-connect-right-side 2md:border-l border-white-30  w-full">
                  <img
                    className="mr-4 lg:mx-auto lg:mb-3"
                    src={whatsapp}
                    alt="decodeup"
                  />
                  <a
                    target="_blank"
                    href="https://wa.me/message/UXSURN63L42OA1"
                    className="block text-left mms:text-center underline font-poppings-bold text-white text-h6"
                  >
                    Chat with our Recruitment Team
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-6-2 px-5 pb-10 md:pt-10 md:px-10 lg:px-50 xl:px-16 md:pb-86">
            <div>
              <h4 className="pb-6 font-poppings-bold  text-rp1 lg:text-h4 text-white">
                Or Submit your resume / CV
              </h4>
            </div>
            <form className="w-full getIntouchForm" noValidate onSubmit={contactSubmit}>
              <div className=" w-full mb-3-3 md:mb-4-2 ">
                <input
                  className={`${errors.firstname ? "err-box" : ""
                    } form-input px-6 py-3-1 w-full text-p3 font-worksans-normal focus:outline-none`}
                  type="text"
                  name="firstname"
                  placeholder="Full Name*"
                  onChange={handleChange}
                  value={fields.firstname || ""}
                />
                {/* {errors.firstname && (
                            <p className="is-danger">{errors.firstname}</p>
                        )} */}
              </div>
              <div className=" w-full mb-3-3 md:mb-4-2">
                <input
                  className={`${errors.lastname ? "err-box" : ""
                    } form-input px-6 py-3-1 w-full text-p3 font-worksans-normal focus:outline-none`}
                  type="text"
                  name="lastname"
                  placeholder="Last Name*"
                  onChange={handleChange}
                  value={fields.lastname || ""}
                />
                {/* {errors.lastname && (
                            <p className="is-danger">{errors.lastname}</p>
                        )} */}
              </div>
              <div className="w-full mb-3-3 md:mb-4-2 ">
                <input
                  className={`${errors.email ? "err-box" : ""} form-input px-6 py-3-1 w-full text-p3 font-worksans-normal focus:outline-none`}
                  type="email"
                  name="email"
                  placeholder="Email*"
                  onChange={handleRgxValidation}
                  value={fields.email || ""}
                />
                {/* {errors.email && (
                            <p className="is-danger">{errors.email}</p>
                        )} */}
              </div>
              <div className=" w-full mb-3-3 md:mb-4-2 ">
                <input
                  className={`${errors.phone ? "err-box" : ""
                    } form-input px-6 py-3-1 w-full text-p3 font-worksans-normal focus:outline-none`}
                  type="text"
                  name="phone"
                  placeholder="Phone*"
                  onChange={handleRgxValidation}
                  value={fields.phone || ""}
                />
                {/* {errors.phone && (
                            <p className="is-danger">{errors.phone}</p>
                        )} */}
              </div>
              <div className=" w-full mb-3-3 md:mb-4-2 ">
                <textarea
                  className={`${errors.somethingAboutYourself ? "err-box" : ""
                    } form-input-msg px-6 py-2-3 w-full text-p4 lg:text-p3 font-worksans-normal text-white font-worksans-normal bg-transparent border border-celeste border-opacity-20 focus:outline-none`}
                  placeholder="Cover Letter: Tell us what is the best thing, that you feel, about what you do*"
                  type="text"
                  name="somethingAboutYourself"
                  rows="10"
                  onChange={handleChange}
                  value={fields.somethingAboutYourself || ""}
                ></textarea>
                {/* {errors.somethingAboutYourself && (
                            <p className="is-danger">{errors.somethingAboutYourself}</p>
                        )} */}
              </div>

              {/* links input fields */}

              <div className={`${errors.twitter ? "err-box" : ""}  w-full mb-3-3 md:mb-3 flex flex-row form-input items-center`}>
                <div className="ml-4-3 mr-3">
                  <img
                    src={Inputtwitter}
                    className="w-6 h-6"
                    alt="rightarrow"
                  ></img>
                </div>
                <div className="w-full">
                  <input
                    className={`form-link-input pr-6 py-3-1 w-full text-p3 font-worksans-normal focus:outline-none`}
                    type="url"
                    name="twitter"
                    placeholder="http://twitter.com/example"
                    onChange={handleRgxValidation}
                    value={fields.twitter || ""}
                  />
                </div>
              </div>
              <div className={`${errors.github ? "err-box" : ""}  w-full mb-3-3 md:mb-3 flex flex-row form-input items-center`}>
                <div className="ml-4-3 mr-3">
                  <img
                    src={Inputgithub}
                    className="w-6 h-6"
                    alt="rightarrow"
                  ></img>
                </div>
                <div className="w-full">
                  <input
                    className={`form-link-input pr-6 py-3-1 w-full text-p3 font-worksans-normal focus:outline-none`}
                    type="url"
                    name="github"
                    placeholder="https://github.com/example"
                    onChange={handleRgxValidation}
                    value={fields.github || ""}
                  />
                </div>
              </div>
              <div className={` w-full mb-3-3 md:mb-3 flex flex-row form-input items-center ${errors.dribble ? "err-box" : ""}`}>
                <div className="ml-4-3 mr-3">
                  <img
                    src={Inputdribbble}
                    className="w-6 h-6"
                    alt="inputdribbble decodeup-job-opening"
                  ></img>
                </div>
                <div className="w-full">
                  <input
                    className={` form-link-input pr-6 py-3-1 w-full text-p3 font-worksans-normal focus:outline-none`}
                    type="url"
                    name="dribble"
                    placeholder="https://dribbble.com/example"
                    onChange={handleRgxValidation}
                    value={fields.dribble || ""}
                  />
                </div>
              </div>
              <div className={`${errors.behance ? "err-box" : ""} w-full mb-3-3 md:mb-3 flex flex-row form-input items-center`}>
                <div className="ml-4-3 mr-3">
                  <img
                    src={Inputbehance}
                    className="w-6 h-6"
                    alt="InputBehance decodeup-job-opening"
                  ></img>
                </div>
                <div className="w-full">
                  <input
                    className="form-link-input pr-6 py-3-1 w-full text-p3 font-worksans-normal focus:outline-none"
                    type="url"
                    name="behance"
                    placeholder="https://www.behance.net/example"
                    onChange={handleRgxValidation}
                    value={fields.behance || ""}
                  />
                </div>
              </div>
              {/* select dropdown */}

              <div className="mb-3-3 md:mb-3 select-form-menu form-input relative">
                <select
                  className={`${errors.whereYouHearAboutThisJob ? "err-box" : ""
                    } cursor-pointer whitespace-normal leading-5 w-full pl-6 pr-68 py-3-1 text-p3 font-worksans-normal bg-background-secondary focus:outline-none`}
                  name="whereYouHearAboutThisJob"
                  onChange={handleChange}
                  value={fields.whereYouHearAboutThisJob || ""}
                >
                  <option value="">
                    From where did you hear about this job
                  </option>
                  <option value="Friends">Friends</option>
                  <option value="Search Engine">Search Engine</option>
                  <option value="Social media">Social media</option>
                </select>
              </div>
              {/* {errors.whereYouHearAboutThisJob && (
                        <p className="is-danger">{errors.whereYouHearAboutThisJob}</p>
                    )} */}

              {/* upload doc section */}

              <div className=" w-full mb-3-3 md:mb-3 flex flex-row items-center md:justify-between">
                <div
                  className={`${errors.resumeCV ? "err-box" : ""
                    } mr-0 w-full h-72 border doc-upload`}
                >
                  <div className="h-full mr-2 md:mr-0 relative text-center">
                    <input
                      type="file"
                      name="resumeCV"
                      id="upload-resume"
                      onChange={handleFileChange}
                    />
                    <label
                      className=" flex flex-row justify-center items-center cursor-pointer absolute w-full h-full top-0 left-0 text-p4 font-worksans-normal text-dark-grey"
                      htmlFor="upload-resume"
                    >
                      <img
                        className="w-15 h-15"
                        src={uploadCv}
                        alt="decodeup Resume Cv uploadCv"
                      />
                      <span className="pl-5" id="resumeText">
                        {resumeText}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <p className="text-rp4 md:text-p3 opacity-30 text-white font-worksans-normal">
                (hint: please upload only .png, .jpg, .pdf, .doc, .docx, .odt, .ods file)
              </p>
              {/* <input type="file" /> */}

              <div className="mt-4-2 mb-3-3 lg:mb-8 lg:mt-6 flex  items-top">
                <label className="checkbox-label">
                  <input
                    className="hidden mr-2"
                    type="checkbox"
                    name="acceptTerm"
                  />
                  <span className="checkbox-custom border-opacity-20 border-celeste border"></span>
                </label>
                <span className="pl-4-1 ml-2-2 lg:ml-4-1 text-rh6 md:text-p3 text-white font-worksans-normal">
                  I want you to keep my information for all future positions I
                  might be fit for.If something interesting pops up, send me
                  an e-mail.
                </span>
              </div>
              {errors.acceptTerm && (
                <p className="is-danger">{errors.acceptTerm}</p>
              )}
              <div className="block sm:flex flex-wrap items-end justify-between">
                <div className="pt-3 lg:pt-0 sm:pl-3 lg:pl-0 w-full">
                  <button
                    className={`px-6 w-full py-3-1 text-p3 focus:outline-none text-white font-poppings-normal bg-cyan  hover:shadow-none transition-all duration-100 ease-in-out delay-0 ${loading ? "cursor-wait opacity-50" : ""
                      } `}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <span className="text-red-400">{errHandler}</span>
            {succMsg && (
              <p className="mt-3-3 md:mt-4-2 text-p2 font-worksans-normal text-green-500">
                Your application successfully submitted!!
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplyForJobForm
